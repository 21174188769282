<template>
  <componentLayout title="Project and Boards">
    <template #menu>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <!-- <router-link
            style="color: #000"
            class="text-decoration-none"
            :to="{ name: 'CreateBoard', params: { project } }"
          >
            <v-list-item v-ripple="{ center: true }">
              ADD
            </v-list-item></router-link
          > -->
        </v-list>
        <v-list>
          <!-- <router-link
            style="color: #000"
            class="text-decoration-none"
            :to="{ name: 'CreateBoard', params: { project } }"
          >
            <v-list-item v-ripple="{ center: true }">
              EDIT
            </v-list-item></router-link
          > -->
        </v-list>
        <v-list>
          <!-- <router-link
            style="color: #000"
            class="text-decoration-none"
            :to="{ name: 'CreateBoard', params: { project } }"
          >
            <v-list-item v-ripple="{ center: true }">
              DELETE
            </v-list-item></router-link
          > -->
        </v-list>
      </v-menu>
    </template>
    <v-row class="d-flex align-center mb-4 px-3">
      <!-- <p class="font-weight-bold p-0 mr-3 pt-3"><u>PROJECT</u></p> -->
      <v-select
        v-bind="basic"
        class="mr-3"
        style="width: 50px"
        :items="filterCriteriaList"
        label="Filter Project By"
        v-model="filterCriteria.status"
      ></v-select>
      <v-text-field
        v-bind="basic"
        style="width: 50px"
        label="Search"
        append-icon="search"
        value=""
        outlined
        v-model="filterCriteria.q"
      ></v-text-field>
    </v-row>
    <v-treeview
      v-if="projects.length > 0 && !loading"
      v-model="tree"
      :open="initiallyOpen"
      :items="computedItems"
      activatable
      item-key="id"
      open-on-click
      dense
    >
      <template v-slot:prepend="{ item }">
        <v-icon v-if="'parent_id' in item && item.parent_id"
          >mdi-calendar-check-outline</v-icon
        >
        <v-icon v-else> mdi-{{ item.icon }} </v-icon>
      </template>

      <template #label="{ item }">
        <div>{{ item.name }}</div>
      </template>
      <template #append="{ item }">
        {{ item.id }}
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-auto bg-btn" dark icon v-bind="attrs" v-on="on">
              <v-icon color="black">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group color="primary">
              <v-list-item
                @click="itemClicked(item, lItem)"
                dense
                v-for="(lItem, i) in item.context_action"
                :key="i"
              >
                <v-list-item-icon>
                  <v-icon color="black">{{ lItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="lItem.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>
    </v-treeview>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="deep-purple accent-4"
    ></v-progress-linear>

    <div class="row">
      <div class="col-md-12">
        <v-pagination
          :length="filterCriteria.length"
          v-model="filterCriteria.page"
          @input="paginationClicked"
        ></v-pagination>
      </div>
    </div>
    <v-dialog v-model="showProjectEditForm" max-width="800px">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <CreateProject :edit="clickedItem" />
          </div>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="addBoard" max-width="800px">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <create-board></create-board>
          </div>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="addTask" max-width="800px">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <add-task
              :boardId="boardId"
              :parentId="parentId"
              @reloadResource="reloadResource"
              @cancel="cancel"
            ></add-task>
          </div>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="editTask" max-width="800px">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <edit-task
              :taskId="taskId"
              @reloadResource="reloadResource"
              @cancel="cancel"
            ></edit-task>
          </div>
        </div>
      </div>
    </v-dialog>
    <!-- <v-dialog v-model="editBoard" max-width="800px">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <edit-task></edit-task>
          </div>
        </div>
      </div>
    </v-dialog> -->
  </componentLayout>
</template>

<script>
import util from "@/util/util";
import componentLayout from "@/components/component-layout.vue";
import CreateProject from "@/components/Project/CreateProject.vue";
import AddTask from "@/components//Board/AddTask.vue";
import EditTask from "@/components//Board/EditTask.vue";
import CreateBoard from "@/components/Project/CreateBoard.vue";
export default {
  components: {
    componentLayout,
    CreateProject,
    AddTask,
    EditTask,
    CreateBoard,
    // popUpLayout,
  },
  props: {},
  mounted() {
    this.getProjectFilters();
    this.getProjectsWithBoardsAndTasks();
    util.event.$on("reload-resource", () => {
      this.getProjectsWithBoardsAndTasks();
      this.showProjectEditForm = false;
      this.addBoard = false;
      this.editTask = false;
      this.addTask = false;
    });
  },

  methods: {
    itemClicked(item, action) {
      if (action.text == "Gantt Chart") {
        this.$router.push(action.url);
      }
      console.log(item);
      this.clickedItem = item;

      if (action.text == "Create Board") {
        this.addBoard = true;
      } else if (action.text == "Edit Project") {
        this.showProjectEditForm = true;
      } else if (action.text == "Create Task") {
        this.boardId = item.id;
        this.addTask = true;
      } else if (action.text == "Edit Task") {
        this.taskId = item.id;
        this.editTask = true;
      } else if (action.text == "Create Sub Task") {
        this.boardId = item.board_id;
        this.parentId = item.id;
        this.addTask = true;
      } else if (action.text == "Edit Sub Task") {
        this.taskId = item.id;
        this.editTask = true;
      }
    },
    paginationClicked(page) {
      this.filterCriteria.page = page;
    },
    getProjectFilters() {
      util
        .http({
          url: "filters/tm-project",
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            this.filterCriteriaList = resp.data.data;
          }
        });
    },
    getProjectsWithBoardsAndTasks() {
      this.loading = true;
      util
        .http({
          url: "tm/project/all-projects-with-boards-and-tasks",
          params: this.filterCriteria,
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            this.projects = resp.data.data.data;
            this.filterCriteria.length = resp.data.data.last_page;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reloadResource() {
      this.addTask = false;
      this.editTask = false;
      this.boardId = null;
      this.taskId = null;
      this.parentId = null;
      this.getProjectsWithBoardsAndTasks();
    },
    cancel() {
      this.addTask = false;
      this.editTask = false;
      this.boardId = null;
      this.taskId = null;
      this.parentId = null;
    },
  },
  watch: {
    filterCriteria: {
      deep: true,
      handler: function() {
        this.getProjectsWithBoardsAndTasks();
      },
    },
  },
  computed: {
    computedItems() {
      return util.constructTree(this.projects);
    },
  },

  data: () => ({
    loading: true,
    projects: [],
    initiallyOpen: ["public"],
    basic: util.input.basic,
    filterCriteria: {
      length: 0,
      page: 1,
      search: "",
      status: "Latest",
    },
    tree: [],
    filterCriteriaList: [],
    showProjectEditForm: false,
    addBoard: false,
    addTask: false,
    editTask: false,
    boardId: null,
    taskId: null,
    parentId: null,
    clickedItem: null,
  }),
};
</script>

<style scoped lang="scss">
.home-hover:hover {
  .icon {
    display: block;
  }
}
.home-hover {
  .icon {
    display: none !important;
  }
}
</style>
