<template>
  <div class="team pl-5">
    <h1 class="subheading grey--text mb-5">Dashboard</h1>
    <!-- <v-container> -->
      <v-layout row wrap>
        <v-flex sm6 xs12 md6>
          <ProjectTree />
        </v-flex>
      </v-layout>
    <!-- </v-container> -->
  </div>
</template>

<script>
import ProjectTree from "@/components/Project/ProjectTree";
// @ is an alias to /src
export default {
  name: "team",
  components: {
    ProjectTree,
  },
  data: () => ({
    team: [
      { name: "Iyad", role: "web developer", avatar: "/img1.png" },
      { name: "Reda", role: "Graphic designer", avatar: "/img2.png" },
      { name: "Zineb", role: "web developer", avatar: "/img3.png" },
      { name: "Hu TechGroup", role: "Desktop developer", avatar: "/img4.png" },
    ],
  }),
};
</script>